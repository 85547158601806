<template>
  <div>
    <table-header
      :fields="exportFields"
      :name="'activities'"
      @export-xls="exportActivities"
      @search="searchActivity"
    >
      <template #update-button>
        <b-button
          v-if="isEditing"
          ref="saveEntryButton"
          size="is-small"
          class="mr-4"
          icon-left="content-save"
          type="is-success"
          @click="updateActivity"
        >
          save
        </b-button>
        <b-button
          v-if="isEditing"
          ref="cancelEntryButton"
          size="is-small"
          icon-left="close"
          type="is-success"
          @click="cancelUpdate"
        >
          cancel
        </b-button>
      </template>

      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0"
          size="is-small"
          icon="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.stickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
          class="dg-columns"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>
          <draggable
            v-model="columns"
            group="columns"
            handle=".handle"
            @start="drag = true"
            @end="drag = false"
          >
            <b-dropdown-item
              v-for="column in columns"
              :key="column.id"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{ column.label }}
                </b-checkbox>
                <i class="grip-vertical handle" />
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="activitiesTable"
      v-click-outside-dg="onClickOutsideTable"
      :mobile-cards="false"
      :loading="loading"
      :data="activities.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @update:selected="onClickOutsideEditing"
    >
      <b-table-column
        field="documents"
        :searchable="false"
        :visible="true"
        cell-class="chevron-cell"
      >
        <template v-slot="props">
          <div>
            <b-icon
              v-if="props.row.documents && props.row.documents.length > 0"
              icon="paperclip"
              class="has-text-info ml-4"
              size="is-small"
            />
          </div>
        </template>
      </b-table-column>

      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template>
            <b-input
              v-if="
                column.searchField === 'createdAt' ||
                  column.searchField === 'updatedAt' ||
                  column.searchField === 'meetingDate'
              "
              v-model="props.filters[column.searchField]"
              type="date"
              icon="calendar-month"
              size="is-small"
            />

            <b-input
              v-if="column.searchField === 'dueDate'"
              v-model="props.filters[column.searchField]"
              type="datetime-local"
              icon="calendar-month"
              size="is-small"
            />

            <b-checkbox
              v-if="column.field === 'isComplete'"
              v-model="props.filters[column.searchField]"
              size="is-small"
            />

            <b-select
              v-if="column.field === 'taskPriority'"
              v-model="props.filters[column.searchField]"
              size="is-small"
            >
              <option
                v-for="(taskPriority, taskPriorityIndex) in taskPriorities"
                :key="taskPriorityIndex"
                :value="taskPriority.value"
              >
                {{ taskPriority.value }}
              </option>
            </b-select>

            <b-select
              v-if="column.searchField === 'taskType'"
              v-model="props.filters[column.searchField]"
              size="is-small"
            >
              <option
                v-for="(taskType, taskTypeIndex) in taskTypes"
                :key="taskTypeIndex"
                :value="taskType.value"
              >
                {{ taskType.value }}
              </option>
            </b-select>

            <b-select
              v-if="column.searchField === 'activityType'"
              id="activityType"
              v-model="props.filters[column.searchField]"
              size="is-small"
            >
              <option
                v-for="(activityType, activityTypeIndex) in activityTypes"
                :key="activityTypeIndex"
                :value="activityType.value"
              >
                {{ activityType.value }}
              </option>
            </b-select>

            <b-input
              v-if="
                column.searchField === 'activityName' ||
                  column.searchField === 'parent' ||
                  column.searchField === 'description' ||
                  column.searchField === 'owner'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div
              v-if="readOnly === false"
              class="buttons"
            >
              <b-tooltip
                type="is-info"
                label="Edit"
                position="is-right"
              >
                <b-button
                  v-if="$route.name == 'ViewAccount'"
                  tag="router-link"
                  :to="{
                    name: 'EditActivity',
                    params: {
                      activityId: props.row.id,
                      redirectParams: {
                        name: $route.name,
                        params: { accountId: props.row.accountId },
                        query: { activeTab: 4 },
                      }
                    },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                />
                <b-button
                  v-if="$route.name == 'ViewContact'"
                  tag="router-link"
                  :to="{
                    name: 'EditActivity',
                    params: {
                      activityId: props.row.id,
                      redirectParams: {
                        name: $route.name,
                        params: { contactId: props.row.contactId },
                        query: { activeTab: 4 },
                      }
                    },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                />
                <b-button
                  v-else-if="$route.name == 'ViewLead'"
                  tag="router-link"
                  :to="{
                    name: 'EditActivity',
                    params: {
                      activityId: props.row.id,
                      redirectParams: {
                        name: $route.name,
                        params: { leadId: props.row.opportunityId },
                        query: { activeTab: 1 },
                      }
                    },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                />
                <b-button
                  v-else-if="$route.name == 'ViewOpportunity'"
                  tag="router-link"
                  :to="{
                    name: 'EditActivity',
                    params: {
                      activityId: props.row.id,
                      redirectParams: {
                        name: $route.name,
                        params: { opportunityId: props.row.opportunityId },
                        query: { activeTab: 1 },
                      }
                    },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                />
                <b-button
                  v-else-if="$route.name == 'ListActivities'"
                  tag="router-link"
                  :to="{
                    name: 'EditActivity',
                    params: {
                      activityId: props.row.id
                    },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                />
              </b-tooltip>

              <!--<b-tooltip
                v-if="
                  props.row.activityType === 'Event' ||
                    props.row.activityType === 'Task'
                "
                type="is-info"
                label="Notification"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="bell"
                  @click="$emit('notification', props.row)"
                />
              </b-tooltip>-->

              <b-tooltip
                type="is-primary"
                label="Delete"
                position="is-bottom"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="trash-can"
                  @click="deleteActivity(props.row.id)"
                />
              </b-tooltip>
            </div>
            <div v-else />
          </template>

          <template v-else-if="column.field === 'parent'">
            <template v-if="props.row.parent">
              <template v-if="props.row.parent.startsWith('Account')">
                <strong>Account</strong> -
                <router-link
                  class="has-text-info has-text-weight-semibold is-capitalized"
                  :to="{
                    name: 'ViewAccount',
                    params: { accountId: props.row.accountId },
                    query: { activeTab: 4 },
                  }"
                >
                  {{ props.row.parent.split('-').slice(1).join("-") }}
                </router-link>
              </template>

              <template v-if="props.row.parent.startsWith('Contact')">
                <strong>Contact</strong> -
                <router-link
                  class="has-text-info has-text-weight-semibold is-capitalized"
                  :to="{
                    name: 'ViewContact',
                    params: { contactId: props.row.contactId },
                    query: { activeTab: 4 },
                  }"
                >
                  {{ props.row.parent.split('-').slice(1).join("-") }}
                </router-link>
              </template>

              <template v-if="props.row.parent.startsWith('Lead')">
                <strong>Lead</strong> -
                <router-link
                  class="has-text-info has-text-weight-semibold is-capitalized"
                  :to="{
                    name: 'ViewLead',
                    params: { leadId: props.row.opportunityId },
                    query: { activeTab: 1 },
                  }"
                >
                  {{ props.row.parent.split('-').slice(1).join("-") }}
                </router-link>
              </template>

              <template v-if="props.row.parent.startsWith('Opportunity')">
                <strong>Opportunity</strong> -
                <router-link
                  class="has-text-info has-text-weight-semibold"
                  :to="{
                    name: 'ViewOpportunity',
                    params: { opportunityId: props.row.opportunityId },
                    query: { activeTab: 1 },
                  }"
                >
                  {{ props.row.parent.split('-').slice(1).join("-") }}
                </router-link>
              </template>
            </template>
          </template>

          <b-tooltip
            v-else
            label="Double-click to edit"
            class="is-info"
          >
            <template v-if="column.field === 'taskPriority'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedActivityId &&
                    props.row.activityType === 'Task'
                "
              >
                <b-select
                  id="priority"
                  v-model="taskPriorityId"
                  expanded
                >
                  <option
                    v-for="(taskPriority, taskPriorityIndex) in taskPriorities"
                    :key="taskPriorityIndex"
                    :value="taskPriority.id"
                  >
                    {{ taskPriority.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field] !== null"
                  @dblclick="onEdit(props.row)"
                >
                  <b-tag
                    :type="
                      props.row[column.field] === 'Urgent'
                        ? 'is-danger'
                        : 'is-primary'
                    "
                  >
                    {{ props.row[column.field] }}
                  </b-tag>
                </span>
                <span
                  v-else-if="props.row.activityType === 'Task'"
                  @dblclick="onEdit(props.row)"
                >
                  -
                </span>
              </template>
            </template>

            <template
              v-else-if="
                column.field === 'activityType' || column.field === 'taskType'
              "
            >
              {{ props.row[column.field] }}
            </template>

            <template v-else-if="column.field === 'activityName'">
              <template v-if="isEditing && props.row.id === selectedActivityId">
                <b-input
                  id="activityName"
                  v-model="activityName"
                  size="is-small"
                />
              </template>

              <span
                v-else
                class="has-text-weight-bold has-text-primary is-pointer"
                @click="$router.push(`/activities/${props.row.id}/edit`)"
                @dblclick="onEdit(props.row)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'description'">
              <b-field v-if="isEditing && props.row.id === selectedActivityId">
                <b-input
                  v-model="description"
                  size="is-small"
                  type="textarea"
                />
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field] !== ''"
                  class="is-pointer is-wrapped"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] | truncateText }}
                </span>
                <span
                  v-else
                  @dblclick="onEdit(props.row)"
                >
                  -
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'owner'">
              <b-field v-if="isEditing && props.row.id === selectedActivityId">
                <b-autocomplete
                  id="owner"
                  v-model="owner"
                  size="is-small"
                  icon-right="search"
                  field="fullName"
                  :data="usersSearchResults"
                  @typing="getUsersAsyncData"
                  @select="(option) => (selectedOwner = option)"
                >
                  <template slot-scope="user">
                    <div class="media">
                      <div class="media-content">
                        {{ user.option.fullName.trim() }}
                        <br>
                        <small>
                          email: {{ user.option.email }} <br>
                          Phone: {{ user.option.phoneCell }}
                        </small>
                      </div>
                    </div>
                  </template>

                  <template #empty>
                    No results for {{ owner }}
                  </template>
                </b-autocomplete>
              </b-field>

              <template v-else>
                <span
                  class="has-text-info has-text-weight-bold"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row.owner }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'meetingDate'">
              <template
                v-if="
                  isEditing &&
                    props.row.id === selectedActivityId &&
                    props.row.activityType === 'Event'
                "
              >
                <b-field>
                  <b-input
                    v-model="meetingDate"
                    type="date"
                    icon="calendar-month"
                    size="is-small"
                  />
                </b-field>

                <b-field
                  grouped
                  group-multiline
                >
                  <b-field>
                    <b-input
                      v-model="meetingStartTime"
                      size="is-small"
                      type="time"
                    />
                  </b-field>

                  <b-field>
                    <b-input
                      v-model="meetingEndTime"
                      size="is-small"
                      type="time"
                    />
                  </b-field>
                </b-field>
              </template>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  style="display: block"
                  @dblclick="onEdit(props.row)"
                >
                  <p>Date: {{ props.row.meetingDate | date }}</p>
                  <p>
                    <b-tag>
                      {{
                        new Date(
                          `${props.row.meetingDate}T${props.row.startTime}`
                        ).toLocaleTimeString()
                      }}
                      -
                      {{
                        new Date(
                          `${props.row.meetingDate}T${props.row.endTime}`
                        ).toLocaleTimeString()
                      }}
                    </b-tag>
                  </p>
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'dueDate'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedActivityId &&
                    props.row.activityType === 'Task'
                "
              >
                <b-input
                  id="dueDate"
                  v-model="dueDate"
                  type="datetime-local"
                  icon="calendar-month"
                  size="is-small"
                />
              </b-field>
              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] | dateTime }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'isComplete'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedActivityId &&
                    (props.row.activityType === 'Task' ||
                      props.row.activityType === 'Event')
                "
              >
                <b-checkbox
                  id="isComplete"
                  v-model="isComplete"
                  size="is-small"
                />
              </b-field>

              <template v-else>
                <b-checkbox
                  v-if="props.row[column.field]"
                  size="is-small"
                  :value="props.row[column.field]"
                  type="is-grey"
                  disabled
                />
              </template>
            </template>

            <template
              v-else-if="
                column.field === 'createdAt' || column.field === 'updatedAt'
              "
            >
              {{ props.row[column.field] | date }}
            </template>
          </b-tooltip>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="activities" />
      </template>
    </b-table>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateActivity"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import {
  TableFooter, TableHeader, EmptyTable, InlineEditingSavePrompt,
} from '@/components/Shared';
import SearchMixin from '@/mixins/SearchMixin';

export default {
  name: 'ActivitiesTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    draggable,
    InlineEditingSavePrompt,
  },

  filters: {
    /**
     * Truncate text that is more than 100 characters in length
     *
     * @param {String} text text to truncate
     * @returns truncated text
     */
    truncateText(text) {
      const charactersLength = 100;
      const textEnding = '...';
      if (text.length <= charactersLength) {
        return text;
      }
      return text.slice(0, charactersLength - textEnding.length) + textEnding;
    },
  },

  mixins: [SearchMixin],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    checkedRows: [],

    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isEditing: false,
    selectedActivityId: '',

    isSavePromptActive: false,

    savePromptOpen: false,

    filters: {},

    exportFields: {},

    searchClause: '',

    columns: [
      {
        id: '1',
        label: 'Activity Name',
        field: 'activityName',
        sortable: true,
        searchable: true,
        searchField: 'activityName',
        exportField: 'activityName',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Account',
        field: 'parent',
        sortable: true,
        searchable: true,
        searchField: 'parent',
        exportField: 'parent',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Activity Type',
        field: 'activityType',
        sortable: true,
        searchable: true,
        searchField: 'activityType',
        exportField: 'activityType',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Task Type',
        field: 'taskType',
        sortable: true,
        searchable: true,
        searchField: 'taskType',
        exportField: 'taskType',
        sticky: false,
        visible: false,
      },
      {
        id: '5',
        label: 'Priority',
        field: 'taskPriority',
        sortable: true,
        searchable: true,
        searchField: 'taskPriority',
        exportField: 'taskPriority',
        sticky: false,
        visible: true,
      },
      {
        id: '6',
        label: 'Description',
        field: 'description',
        sortable: true,
        searchable: true,
        searchField: 'description',
        exportField: 'description',
        sticky: false,
        visible: true,
      },
      {
        id: '7',
        label: 'Owner',
        field: 'owner',
        sortable: true,
        searchable: true,
        searchField: 'owner',
        exportField: 'owner',
        sticky: false,
        visible: true,
      },
      {
        id: '8',
        label: 'Meeting Date',
        field: 'meetingDate',
        sortable: true,
        searchable: true,
        searchField: 'meetingDate',
        exportField: 'exportMeetingDate',
        sticky: false,
        visible: true,
      },
      {
        id: '9',
        label: 'Due Date',
        field: 'dueDate',
        sortable: true,
        searchable: true,
        searchField: 'dueDate',
        exportField: 'dueDate',
        sticky: false,
        visible: true,
      },
      {
        id: '10',
        label: 'Complete',
        field: 'isComplete',
        sortable: true,
        searchable: true,
        searchField: 'isComplete',
        exportField: 'exportIsComplete',
        sticky: false,
        visible: true,
      },
      {
        id: '11',
        label: 'Created On',
        field: 'createdAt',
        sortable: true,
        searchable: true,
        searchField: 'createdAt',
        exportField: 'createdAt',
        sticky: false,
        visible: false,
      },
      {
        id: '12',
        label: 'Modified On',
        field: 'updatedAt',
        sortable: true,
        searchable: true,
        searchField: 'updatedAt',
        exportField: 'updatedAt',
        sticky: false,
        visible: false,
      },
      {
        id: '13',
        label: 'Actions',
        field: 'actions',
        sortable: true,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

    selectedOwner: null,
  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getGrid',
      activities: 'DataGrids/getActivities',
      loading: 'DataGrids/getLoading',
      activity: 'Activities/getActivity',
      activityTypes: 'Lookups/getActivityTypes',
      taskPriorities: 'Lookups/getTaskPriorities',
      taskTypes: 'Lookups/getTaskTypes',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    body() {
      return this.checkedRows.length >= 1 ? this.checkedRows : this.activities;
    },

    activityName: {
      get() {
        return this.$store.state.Activities.activity.activityName;
      },

      set(value) {
        return this.$store.commit(
          'Activities/SET_ACTIVITY_ACTIVITY_NAME',
          value,
        );
      },
    },

    owner: {
      get() {
        return this.$store.state.Activities.activity.owner;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_OWNER', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Activities.activity.description;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_DESCRIPTION', value);
      },
    },

    dueDate: {
      get() {
        const dueDateValue = this.$store.state.Activities.activity.dueDate;
        if (dueDateValue) {
          /*
           * Return the date time string representation of dueDate string value
           * to be displayed on the date time input form field
           */
          return dueDateValue.split(' ').join('T');
        }
        return dueDateValue;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_DUE_DATE', value);
      },
    },

    meetingDate: {
      get() {
        return this.$store.state.Activities.activity.meetingDate;
      },

      set(value) {
        return this.$store.commit(
          'Activities/SET_ACTIVITY_MEETING_DATE',
          value,
        );
      },
    },

    meetingStartTime: {
      get() {
        return this.$store.state.Activities.activity.meetingStartTime;
      },

      set(value) {
        return this.$store.commit(
          'Activities/SET_ACTIVITY_MEETING_START_TIME',
          value,
        );
      },
    },

    meetingEndTime: {
      get() {
        return this.$store.state.Activities.activity.meetingEndTime;
      },

      set(value) {
        return this.$store.commit(
          'Activities/SET_ACTIVITY_MEETING_END_TIME',
          value,
        );
      },
    },

    taskPriorityId: {
      get() {
        return this.$store.state.Activities.activity.taskPriorityId;
      },

      set(value) {
        return this.$store.commit(
          'Activities/SET_ACTIVITY_TASK_PRIORITY_ID',
          value,
        );
      },
    },

    isComplete: {
      get() {
        return this.$store.state.Activities.activity.isComplete;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_IS_COMPLETE', value);
      },
    },
  },

  watch: {
    perPage: {
      handler() {
        this.fetchActivities();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },

    selectedOwner(value) {
      return this.$store.commit(
        'Activities/SET_ACTIVITY_OWNER_ID',
        value.userId,
      );
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'activities',
        organizationId,
      });
      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'activities',
          customizations: this.customizations,
          filters: this.filters,
        });
      }
      await this.mapSavedFilters();
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    if (Object.keys(this.$route.query).length > 0 && this.$route.path.includes('/activities')) {
      const payload = { ...this.$route.query };
      delete payload?.activeTab;
      this.filters = payload;
    } else if (this.grid.columns
      && (!this.grid.filters || Object.keys(this.grid.filters).length === 0)) {
      this.fetchActivities();
    } else {
      this.fetchActivities();
    }
    this.$store.dispatch('Lookups/fetchActivityTypes');
    this.$store.dispatch('Lookups/fetchTaskPriorities');
    this.$store.dispatch('Lookups/fetchTaskTypes');
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'activities',
        customizations: this.customizations,
        filters: this.filters,
      });
    }
  },

  methods: {
    mapSavedFilters() {
      if (this.grid.filters) {
        this.$refs.activitiesTable.filters = { ...this.grid.filters };
      }
      return '';
    },

    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label).sort();
      const userConfiguration = this.grid.columns.map((el) => el.label).sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedActivityId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map((el, index) => ({
        ...el,
        visible: this.grid.columns[index].visible,
      }));
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
      if (Object.keys(this.$route.query).length === 0) {
        this.filters = this.grid.filters || {};
      }
    },

    /**
     * fetchActivities
     *
     */
    async fetchActivities() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach(
          (key) => filters[key] === '' && delete filters[key],
        );
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, contactId: this.$route.params.contactId };
      }
      if (this.$route.name === 'ViewLead') {
        filters = { ...filters, opportunityId: this.$route.params.leadId };
      }
      if (this.$route.name === 'ViewOpportunity') {
        filters = { ...filters, opportunityId: this.$route.params.opportunityId };
      }
      if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('DataGrids/fetchActivities', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });
        this.total = this.activities.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    async exportActivities() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach(
          (key) => filters[key] === '' && delete filters[key],
        );
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, contactId: this.$route.params.contactId };
      }
      if (this.$route.name === 'ViewLead') {
        filters = { ...filters, opportunityId: this.$route.params.leadId };
      }
      if (this.$route.name === 'ViewOpportunity') {
        filters = { ...filters, opportunityId: this.$route.params.opportunityId };
      }
      if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('Exports/fetchActivities', {
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @param {Number} page
     */
    onPageChange(page) {
      this.page = page;
      this.from += this.perPage;
      this.fetchActivities();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchActivities();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = { ...this.filters, ...filters };
      this.fetchActivities();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.activitiesTable.resetMultiSorting();
      this.$refs.activitiesTable.filters = {};
      this.filters = {};
      this.fetchActivities();
    },

    /**
     * onEdit
     *
     * @param {object} activity
     */
    onEdit(activity) {
      this.$store.dispatch('Activities/fetchActivity', activity.id);
      this.selectedActivityId = activity.id;
      this.isEditing = true;
    },

    /**
     * searchActivity
     *
     */
    searchActivity(value) {
      this.searchClause = value;
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchActivities();
      }, 800);
    },

    /**
     * updateActivity
     *
     */
    async updateActivity() {
      const payload = { ...this.activity };
      if (payload.file.fileName === '') {
        payload.file = null;
      }
      if (payload.reminder.remindAtId === '') {
        payload.reminder = null;
      }
      if (payload.description === '') {
        payload.description = null;
      }
      if (payload.dueDate) {
        payload.dueDate = payload.dueDate.split(' ').join('T');
      }
      try {
        await this.$store.dispatch('Activities/updateActivity', {
          activityId: this.selectedActivityId,
          activity: payload,
        });
        await this.fetchActivities();
        this.isEditing = false;
        this.selectedActivityId = null;
        this.$store.commit('Activities/CLEAR_ACTIVITY');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * cancelUpdate
     *
     */
    async cancelUpdate() {
      try {
        await this.fetchActivities();
        this.isEditing = false;
        this.selectedActivityId = null;
        this.$store.commit('Activities/CLEAR_ACTIVITY');
      } catch (error) {
        console.error(error);
      }
    },

    async deleteActivity(payload) {
      try {
        await this.$store.dispatch('Activities/deleteActivity', payload);
        await this.fetchActivities();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
