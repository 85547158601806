<template>
  <b-tabs>
    <b-tab-item
      v-for="(activityType, index) in activityTypes"
      :key="index"
      :label="activityType.value"
    >
      <div
        v-for="(activity, activityIndex) in activities.content"
        :key="activityIndex"
      >
        <template v-if="activityIndex <= 5">
          <div
            v-if="activity.activityType === activityType.value"
            class="level is-mobile"
          >
            <div class="level-left">
              <div class="level-item">
                <figure class="image is-32x32">
                  <img
                    style="width:32px; height:32px;"
                    class="is-rounded"
                    :src="activity.owner.userPhoto"
                  >
                </figure>
              </div>

              <div class="level-item">
                <div class="has-text-grey">
                  <p class="is-wrapped">
                    <strong class="has-text-info">
                      {{
                        Object.keys(activity.owner).includes('firstName') ?
                          activity.owner.firstName : ''
                      }}

                      {{
                        Object.keys(activity.owner).includes('lastName') ?
                          activity.owner.lastName : ''
                      }}
                    </strong>
                    added a new
                    <strong class="has-text-info">
                      {{
                        Object.keys(activity.activityType).includes('value') ?
                          activity.activityType.value : ''
                      }} - {{ activity.activityName }}
                    </strong>
                  </p>
                  <small>{{ activity.createdAt | date }}</small>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <b-button
        type="is-info"
        icon-left="eye"
        size="is-small"
        aria-label="View More"
        @click="$emit('view-more')"
      >
        view more
      </b-button>
    </b-tab-item>
  </b-tabs>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ActivitiesTabs',

  filters: {
    date(value) {
      return `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString('en-US')}`;
    },
  },

  data: () => ({
    page: 0,
    perPage: 15,
  }),

  computed: mapGetters({
    activities: 'DataGrids/getActivities',
    activityTypes: 'Lookups/getActivityTypes',
  }),

  async created() {
    this.fetchActivityTypes();
  },

  methods: {
    ...mapActions({
      fetchActivityTypes: 'Lookups/fetchActivityTypes',
    }),
  },
};
</script>
