<template>
  <div>
    <FullCalendar
      ref="fullCalendar"
      :options="calendarOptions"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  name: 'ActivitiesCalendar',

  components: {
    FullCalendar,
  },

  data: () => ({
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  }),

  computed: {
    ...mapGetters({
      activities: 'Activities/getCalendarActivities',
      loading: 'Activities/getLoading',
    }),

    calendarOptions() {
      const data = [...this.activities];

      const events = data.map((activity) => ({
        id: activity.id,
        title: this.$options.filters.capitalize(activity.activityName),
        start: `${activity.meetingDate}T${activity.meetingStartTime}`,
        end: `${activity.meetingDate}T${activity.meetingEndTime}`,
        extendedProps: {
          contactDto: activity.contactDto,
          accountDto: activity.accountDto,
          opportunityDto: activity.opportunityDto,
        },
        description: activity.description
          ? this.$options.filters.capitalize(activity.description, { onlyFirstLetter: true })
          : '',
        color: '#ff9f89',
      }));

      return {
        contentHeight: 'auto',
        eventClick: this.handleEventClick,
        events,
        weekends: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        customButtons: {
          prev: {
            // this overrides the prev button
            text: 'PREV',
            click: async () => {
              try {
                const calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.prev();
                const current = calendarApi.getDate();
                const currentMonth = new Date(current).getMonth();
                const month = this.months[currentMonth];
                const year = new Date(current).getFullYear();
                const payload = { month, year };
                await this.$store.dispatch('Activities/fetchCalendarActivities', payload);
              } catch (error) {
                console.error(error);
              }
            },
          },
          next: {
            // this overrides the next button
            text: 'PREV',
            click: async () => {
              try {
                const calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.next();
                const current = calendarApi.getDate();
                const currentMonth = new Date(current).getMonth();
                const month = this.months[currentMonth];
                const year = new Date(current).getFullYear();
                const payload = { month, year };
                await this.$store.dispatch('Activities/fetchCalendarActivities', payload);
              } catch (error) {
                console.error(error);
              }
            },
          },
        },
      };
    },
  },

  async mounted() {
    try {
      const currentMonth = new Date().getUTCMonth();
      const month = this.months[currentMonth];
      const year = new Date().getUTCFullYear();
      const payload = { month, year };
      await this.$store.dispatch('Activities/fetchCalendarActivities', payload);
      this.$store.dispatch('Activities/syncActivities');
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    handleEventClick(clickInfo) {
      if (clickInfo.event.extendedProps.opportunityDto) {
        this.$router.push(`/activities/${clickInfo.event.id}/edit`);
      }
      if (clickInfo.event.extendedProps.contactDto) {
        this.$router.push(`/activities/${clickInfo.event.id}/edit`);
      }
      if (clickInfo.event.extendedProps.accountDto) {
        this.$router.push(`/activities/${clickInfo.event.id}/edit`);
      }
    },
  },
};
</script>

<style lang="scss">
.fc .fc-toolbar-title {
  color: #1e3d73 !important;
  font-weight: 600;
  line-height: 1.125;
}

.fc th {
  background-color: #1e3d73;
  text-align: center !important;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
}

.fc .fc-col-header-cell-cushion {
  color: #ffffff !important;
}

.fc-theme-standard th {
  border: none;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #1e3d73;
  .fc-daygrid-day-number {
    color: #ffffff !important;
  }
}

.fc .fc-daygrid-day-number {
  color: #707070 !important;
  font-weight: bold;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc .fc-button {
  border-radius: 0;
  background-color: #3b90d3;
  border: none;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
}

.fc .fc-button-primary:not(:disabled).fc-button-active.fc-button:hover {
  background-color: #318ad1;
}
</style>
