<template>
  <b-table
    :loading="loading"
    :data="activities"
    narrowed
    striped
  >
    <b-table-column
      v-slot="props"
      label="Type"
      field="activityType"
      sortable
      :visible="type"
    >
      <span v-if="props.row.activityType">{{ props.row.activityType.value }}</span>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Priority"
      field="taskPriority"
      :visible="priority"
    >
      <b-tag
        v-if="props.row.taskPriority"
        :type="props.row.taskPriority.value === 'Urgent' ? 'is-danger' : 'is-primary'"
      >
        {{ props.row.taskPriority.value }}
      </b-tag>
      <span />
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Name"
      field="activityName"
      sortable
      :visible="name"
    >
      {{ props.row.activityName }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Description"
      field="description"
      :visible="description"
    >
      <p>{{ props.row.description }}</p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Owner"
      field="owner"
      :visible="owner"
    >
      <p class="has-text-info has-text-weight-bold">
        {{ props.row.owner.firstName }} {{ props.row.owner.lastName }}
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Date"
      field="dueDate"
      :visible="dueDate"
    >
      <p>
        {{ props.row.dueDate ? new Date(props.row.dueDate).toLocaleDateString() : '' }}
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Complete"
      field="isComplete"
      centered
      :visible="complete"
    >
      <b-checkbox
        v-if="props.row.isComplete"
        :value="props.row.isComplete"
        type="is-grey"
      />
    </b-table-column>

    <b-table-column
      v-slot="props"
      custom-key="actions"
    >
      <b-tooltip
        type="is-info"
        label="Edit"
        position="is-bottom"
      >
        <b-button
          tag="router-link"
          :to="{ name: 'EditActivity', params: { activityId: props.row.id } }"
          type="is-text"
          class="has-text-grey"
          icon-right="pencil"
        />
      </b-tooltip>

      <b-tooltip
        type="is-info"
        label="Notification"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="bell"
          @click="$emit('notification', props.row)"
        />
      </b-tooltip>
    </b-table-column>

    <template
      slot="empty"
    >
      <empty-table table-name="activities" />
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { EmptyTable } from '@/components/Shared';

export default {
  name: 'ActivitiesMinimalTable',

  components: {
    EmptyTable,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  computed: {
    ...mapGetters({
      activities: 'Activities/getEmbeddedActivities',
      loading: 'Activities/getLoading',
    }),
  },

};
</script>

<style lang="css" scoped>
.is-table-footer-spaced {
  margin-top: 0.125rem;
}
</style>
